/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 21:00:51
 */



export const httpConfig = {
  url: process.env.VUE_APP_ENVIRONMENT_URL,
  main: process.env.VUE_APP_ENVIRONMENT_MAIN,
  timeout: 60000,
  errorCode: ["403"],
  successCode: ["0"],
};
