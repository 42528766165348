<!--
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-05-05 15:15:56
-->
<template>
    <a-upload
            :action="httpConfig.main + 'store/v1/upload'"
            name="file"
            list-type="picture-card"
            multiple
            v-model:file-list="fileList"
            @preview="handlePreview"
            @change="handleChange"
            class="avatar-uploader"
            :remove="remove"
    >
        <div v-if="fileList.length < limit">
            <plus-outlined/>
            <div class="ant-upload-text">上传图片</div>
        </div>
    </a-upload>
    <!-- 預覽 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
</template>
<script>
    import {PlusOutlined} from "@ant-design/icons-vue";
    import {defineComponent} from "vue";
    import useImageUpload from "./useImageUpload.js";
    import {httpConfig} from "@/common/config/http.config";

    export default defineComponent({
        components: {
            PlusOutlined,
        },

        props: {
            limit: {
                type: Number,
                default: 9,
            },
            srcArr: {
                tpye: Array,
                default: () => new Array(),
            },
            defaultSrc: {
                type: String,
                default: "",
            },
            hasDefault: {
                type: Boolean,
                default: false,
            },
            isArray: {
                type: Boolean,
                default: false,
            },
            remove: {
                type: Function,
                default:()=> ()=> true
            }
        },

        setup(props, ctx) {
            const handleChange = ({fileList}) => {
                const fileFilter = fileList.filter((file) =>
                    file.response ? file.response.code === 20000 : file.url
                );
                if (props.isArray) {
                    const fileMap = fileFilter.map((file) =>
                        Object({url: file.response ? file.response.data.src : file.url, id: file.uid})
                    );
                    ctx.emit("update:srcArr", fileMap);
                } else {
                    const fileMap = fileFilter.map((file) =>
                        file.response ? file.response.data.src : file.url
                    );
                    ctx.emit("update:srcArr", fileMap.join());
                }
            };

            return {
                httpConfig,
                handleChange,
                ...useImageUpload(props, ctx),
            };
        },
    });
</script>

<style lang="less" scoped>
    /* you can make up upload button and sample style by using stylesheets */

    .avatar-uploader > .ant-upload {
        width: 128px;
        height: 128px;
    }

    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }
</style>
