/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-05-04 21:36:07
 */
import {ref, watchEffect, onBeforeUnmount} from "vue";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function useImageUpload(props) {
  const previewVisible = ref(false);
  const previewImage = ref("");
  const fileList = ref([]);

  const stop = watchEffect(() => {
    if (props.hasDefault) {
      if (props.defaultSrc) {
        fileList.value = [];
        if (typeof props.defaultSrc === "string") {
          props.defaultSrc.split(",").forEach((element, index) => {
            fileList.value.push({
              uid: "default" + index,
              name: element,
              status: "done",
              url: element,
            });
          });
        } else if (
            Array.isArray(props.defaultSrc) &&
            props.defaultSrc.every((item) => item)
        ) {
          props.defaultSrc.forEach((element, index) => {
            fileList.value.push({
              uid: element.id || "default" + index,
              name: element.name || element.url,
              status: "done",
              url: element.url,
            });
          });
        }
      }
      stop && stop();
    }
  });

  const handleCancel = () => {
    previewVisible.value = false;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    previewImage.value = file.url || file.preview;
    previewVisible.value = true;
  };

  const clearFiles = () => {
    fileList.value = [];
  };

  onBeforeUnmount(() => {
    clearFiles();
  });


  return {
    previewVisible,
    previewImage,
    fileList,
    clearFiles,
    handleCancel,
    handlePreview,
  };
}
